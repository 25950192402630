import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Divider, List, ListItem, Paper, Link, Button, Container, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const BlogPost = ({ content }) => {
  const theme = useTheme();

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const extractFrontMatter = (content) => {
    const frontMatterRegex = /^---\n([\s\S]*?)\n---/;
    const match = content.match(frontMatterRegex);
    if (match) {
      const frontMatter = match[1].split('\n').reduce((acc, line) => {
        const colonIndex = line.indexOf(':');
        if (colonIndex !== -1) {
          const key = line.slice(0, colonIndex).trim();
          const value = line.slice(colonIndex + 1).trim();
          acc[key] = value;
        }
        return acc;
      }, {});
      const contentWithoutFrontMatter = content.replace(frontMatterRegex, '').trim();
      return { frontMatter, content: contentWithoutFrontMatter };
    }
    return { frontMatter: {}, content };
  };

  const { frontMatter, content: postContent } = extractFrontMatter(content);

  const MarkdownComponents = {
    h1: (props) => <Typography variant="h2" gutterBottom sx={{ fontWeight: 700, color: theme.palette.primary.main, mt: 6, mb: 3 }} {...props} />,
    h2: (props) => <Typography variant="h3" gutterBottom sx={{ fontWeight: 600, color: theme.palette.secondary.main, mt: 5, mb: 2 }} {...props} />,
    h3: (props) => <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, color: theme.palette.text.primary, mt: 4, mb: 2 }} {...props} />,
    h4: (props) => <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, color: theme.palette.text.primary, mt: 3, mb: 2 }} {...props} />,
    h5: (props) => <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, color: theme.palette.text.primary, mt: 2, mb: 1 }} {...props} />,
    h6: (props) => <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600, color: theme.palette.text.secondary, mt: 2, mb: 1 }} {...props} />,
    p: (props) => <Typography variant="body1" paragraph sx={{ lineHeight: 1.8, fontSize: '1.1rem' }} {...props} />,
    a: (props) => <Link color="secondary" underline="hover" sx={{ fontWeight: 500, '&:hover': { color: theme.palette.primary.main } }} {...props} />,
    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <Box sx={{ my: 3 }}>
          <SyntaxHighlighter
            style={atomDark}
            language={match[1]}
            PreTag="div"
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        </Box>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    ul: ({ children }) => (
      <List sx={{
        pl: 4,
        listStyleType: 'disc',
        '& li': { display: 'list-item' },
        '& li > p': { margin: 0 }
      }}>
        {children}
      </List>
    ),
    ol: ({ children }) => (
      <List sx={{
        pl: 4,
        listStyleType: 'decimal',
        '& li': { display: 'list-item' },
        '& li > p': { margin: 0 }
      }}>
        {children}
      </List>
    ),
    blockquote: ({ children }) => (
      <Paper
        elevation={0}
        sx={{
          borderLeft: `4px solid ${theme.palette.primary.main}`,
          pl: 2,
          py: 1,
          my: 4,
          bgcolor: theme.palette.background.paper,
          borderRadius: 1,
          boxShadow: 2,
        }}
      >
        <Typography
          component="blockquote"
          sx={{
            fontStyle: 'italic',
            color: theme.palette.text.secondary,
            fontSize: '1.1rem',
          }}
        >
          {children}
        </Typography>
      </Paper>
    ),
    img: ({ src, alt, ...props }) => (
      <Box sx={{ my: 4, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto', borderRadius: '12px', boxShadow: theme.shadows[4] }} {...props} />
        {alt && <Typography variant="caption" sx={{ mt: 2, display: 'block', color: theme.palette.text.secondary }}>{alt}</Typography>}
      </Box>
    ),
    table: ({ children }) => (
      <Box sx={{ overflowX: 'auto', my: 2 }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>{children}</table>
      </Box>
    ),
    thead: ({ children }) => <thead style={{ borderBottom: `2px solid ${theme.palette.divider}` }}>{children}</thead>,
    tbody: ({ children }) => <tbody>{children}</tbody>,
    tr: ({ children }) => <tr>{children}</tr>,
    th: ({ children }) => (
      <th style={{ padding: '12px', textAlign: 'left', fontWeight: 'bold', backgroundColor: theme.palette.background.default }}>
        {children}
      </th>
    ),
    td: ({ children }) => (
      <td style={{ padding: '12px', borderTop: `1px solid ${theme.palette.divider}` }}>{children}</td>
    ),
    hr: () => <Divider sx={{ my: 2 }} />,
    del: ({ children }) => <Typography component="span" sx={{ textDecoration: 'line-through' }}>{children}</Typography>,
    li: ({ children, checked }) => {
      if (checked !== null && checked !== undefined) {
        return (
          <ListItem
            sx={{
              display: 'list-item',
              pl: 0,
              py: 0.5,
              typography: 'body1',
              '& > p': { margin: 0 },
            }}
            component="li"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" checked={checked} readOnly style={{ marginRight: '8px' }} />
              {children}
            </Box>
          </ListItem>
        );
      }
      return (
        <ListItem
          sx={{
            display: 'list-item',
            pl: 0,
            py: 0.5,
            typography: 'body1',
            '& > p': { margin: 0 },
          }}
          component="li"
        >
          {children}
        </ListItem>
      );
    },
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 12 }}>
      <Button
        component={RouterLink}
        to="/blog"
        startIcon={<ArrowBackIcon />}
        sx={{ mb: 4, fontWeight: 500 }}
      >
        Back to Blog
      </Button>
      <Paper elevation={3} sx={{ p: { xs: 3, sm: 4, md: 6 }, borderRadius: 4, bgcolor: theme.palette.background.paper }}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 700,
            color: theme.palette.primary.main,
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            lineHeight: 1.2
          }}
        >
          {frontMatter.title}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0, mt: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Chip icon={<PersonIcon />} label={frontMatter.author} color="primary" variant="outlined" />
            <Chip icon={<AccessTimeIcon />} label={formatDate(frontMatter.date)} color="secondary" variant="outlined" />
          </Box>
          {frontMatter.url && (
            <Link href={frontMatter.url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center' }}>
              <LinkedInIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Share on LinkedIn</Typography>
            </Link>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
          {frontMatter.tags && frontMatter.tags.split(',').map((tag, index) => (
            <Chip key={index} label={tag.trim()} variant="outlined" size="small" />
          ))}
        </Box>
        <Divider sx={{ my: 4 }} />
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={MarkdownComponents}
        >
          {postContent}
        </ReactMarkdown>
      </Paper>
    </Container>
  );
};

export default BlogPost;
