import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Typography, Link, Grid, IconButton, Divider } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import ArticleIcon from '@mui/icons-material/Article';
import GavelIcon from '@mui/icons-material/Gavel';

const Footer = () => {
  const theme = useTheme();

  const footerLinks = [
    { icon: <LinkedInIcon />, text: 'LinkedIn', href: 'https://www.linkedin.com/company/catena-clearing/' },
    { icon: <EmailIcon />, text: 'Contact Us', href: 'mailto:sales@catenaclearing.io' },
    { icon: <WorkIcon />, text: 'Careers', href: 'https://job-boards.greenhouse.io/catenaclearing' },
    { icon: <ArticleIcon />, text: 'Privacy Policy', href: 'https://cdn.prod.website-files.com/6633b37be0cf9e6d72a205ca/664511af9da1e88312f22671_Website%20-%20Privacy%20Policy.pdf' },
    { icon: <GavelIcon />, text: 'Terms', href: 'https://cdn.prod.website-files.com/6633b37be0cf9e6d72a205ca/664511afc5fc976cdf696a1c_Website%20-%20Terms%20and%20Conditions.pdf' },
  ];

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        py: 6,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Catena Clearing
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Catena Clearing: The Universal Data Connector for supply chains. We bridge sources and destinations, connecting disparate systems to convert raw data into actionable insights, empowering organizations to overcome complex challenges and optimize operations.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Quick Links
            </Typography>
            <Grid container spacing={2}>
              {footerLinks.map((link, index) => (
                <Grid item xs={6} key={index}>
                  <Link
                    href={link.href}
                    color="inherit"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': { color: theme.palette.primary.main },
                      transition: 'color 0.3s',
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {React.cloneElement(link.icon, { sx: { mr: 1, fontSize: '1.2rem' } })}
                    <Typography variant="body2">{link.text}</Typography>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Catena Clearing. All rights reserved.
          </Typography>
          <Box>
            {footerLinks.slice(0, 3).map((link, index) => (
              <IconButton
                key={index}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: theme.palette.text.secondary, '&:hover': { color: theme.palette.primary.main } }}
              >
                {link.icon}
              </IconButton>
            ))}
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          top: -100,
          right: -100,
          width: 300,
          height: 300,
          borderRadius: '50%',
          background: `linear-gradient(45deg, ${theme.palette.primary.main}22, ${theme.palette.secondary.main}22)`,
          zIndex: 0,
        }}
      />
    </Box>
  );
};

export default Footer;
