import React, { useContext, useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { ThemeContext } from './ThemeContext';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const TeamMember = ({ name, role, description, image, theme, linkedin }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="flex flex-col items-center p-4 rounded-lg shadow-lg relative overflow-hidden"
      style={{ backgroundColor: theme.palette.background.paper, width: '300px', height: '300px' }}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 300 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        className="absolute inset-0 flex items-center justify-center"
        animate={{ opacity: isHovered ? 0.2 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <img src={image} alt={name} className="w-full h-full object-cover object-center" />
      </motion.div>
      <motion.div
        className="absolute inset-0 flex flex-col items-center justify-center p-4 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        style={{
          backgroundColor: `${theme.palette.background.paper}cc`,
          color: theme.palette.text.primary
        }}
      >
        <h3 className="text-lg font-semibold mb-2">{name}</h3>
        <p className="text-sm mb-2" style={{ color: theme.palette.text.secondary }}>{role}</p>
        <p className="text-xs mb-2">{description}</p>
        {linkedin && (
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <LinkedInIcon style={{ fontSize: 24, color: theme.palette.primary.main }} />
          </a>
        )}
      </motion.div>
    </motion.div>
  );
};

const TeamPage = () => {
  const { theme } = useContext(ThemeContext);
  const [randomizedTeamMembers, setRandomizedTeamMembers] = useState([]);

  const coFounders = useMemo(() => [
    { name: 'Jeremy Baksht', role: 'CEO & Co-Founder', description: 'Jeremy is a data expert with senior roles at Walmart, Bloomberg, Citigroup, and several startups.', image: '/team/jeremy_baksht.jpeg', linkedin: 'https://www.linkedin.com/in/baksht/' },
    { name: 'Mike Goynes', role: 'CTO & Co-Founder', description: 'Mike has led supply chain data and tech teams for decades, including at Interos, Optoro, Simplexity, and InPhonic.', image: '/team/mike_goynes.jpeg', linkedin: 'https://www.linkedin.com/in/mike-goynes/' },
    { name: 'Travis May', role: 'Chairman & Co-Founder', description: 'Travis is the founder of Shaper Capital, launching world-changing data companies. He previously founded Datavant and LiveRamp.', image: '/team/travis_may.jpeg', linkedin: 'https://www.linkedin.com/in/stmay/' },
  ], []);

  const otherTeamMembers = useMemo(() => [
    { name: 'Neal Hargis', role: 'Senior Sales Engineer', description: 'Neal is a supply chain and logistics expert with experience from Vizion, Chain.io, and BlueGrace Logistics.', image: '/team/neal_hargis.jpeg', linkedin: 'https://www.linkedin.com/in/neal-hargis/' },
    { name: 'Josh Meek', role: 'Principal Software Engineer', description: 'Josh is a versatile software engineer with contributions to NASA, Kensho, Prefect, and Interos.', image: '/team/josh_meek.jpeg', linkedin: 'https://www.linkedin.com/in/josh-meek/' },
    { name: 'Pranav Kayastha', role: 'Founding Engineer', description: 'Pranav has a distinguished career in software development with previous experience at Vecra AI and Interos.', image: '/team/pranav_kayastha.jpeg', linkedin: 'https://www.linkedin.com/in/pkayastha01/' },
    { name: 'André Sionek', role: 'Principal Software Engineer', description: 'André is an experienced software engineer with expertise in AWS infrastructure, APIs, and data engineering, contributing to Volteras, Util, and Gousto.', image: '/team/andre_sionek.jpeg', linkedin: 'https://www.linkedin.com/in/andresionek/' },
  ], []);

  useEffect(() => {
    const shuffledMembers = [...otherTeamMembers].sort(() => Math.random() - 0.5);
    setRandomizedTeamMembers([...coFounders, ...shuffledMembers]);
  }, [coFounders, otherTeamMembers]);

  return (
    <div style={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
      <div className="container mx-auto px-4 py-12">
        <motion.h1
          className="text-4xl font-bold text-center mb-12"
          style={{ color: theme.palette.text.primary }}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Team
        </motion.h1>
        <motion.p
          className="text-xl text-center mb-16 max-w-3xl mx-auto"
          style={{ color: theme.palette.text.secondary }}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Meet the Catena Clearing team: We love being in the middle—the point between data sources and data destinations that helps organizations solve supply chain and logistics problems via our managed data transformation platform.
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
          {randomizedTeamMembers.map((member, index) => (
            <motion.div
              key={member.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <TeamMember {...member} theme={theme} />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
