/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BlogPost from './components/BlogPost';
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Typography, Box, Container } from '@mui/material';

const BlogPage = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const theme = useTheme();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/blog/${slug}.md`);
        if (!response.ok) {
          throw new Error('Failed to fetch blog post');
        }
        const content = await response.text();
        setPost(content);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', bgcolor: theme.palette.background.default }}>
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}> {/* Changed from "sm" to "lg" */}
          <Typography variant="h4" color="error" gutterBottom>
            Oops! Something went wrong
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {error}
          </Typography>
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh' }}>
      {post ? (
        <BlogPost content={post} />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <Container maxWidth="lg" sx={{ textAlign: 'center' }}> {/* Changed from "sm" to "lg" */}
            <Typography variant="h4" gutterBottom>
              Blog post not found
            </Typography>
            <Typography variant="body1" color="textSecondary">
              The blog post you're looking for doesn't exist or has been removed.
            </Typography>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default BlogPage;
